import React, { useState } from "react";
import '../App.css';
import { useTranslation } from "react-i18next";
import { HiX, HiCheck } from "react-icons/hi";
import ContactToast from "./ContactToast";

export default function ContactForm() {
	const { t } = useTranslation();
	const [form, setForm] = useState({
		name: "",
		email: "",
		subject: "",
		message: "",
	});
	const [response, setResponse] = useState();
  const [status, setStatus] = useState("Enviar");

	const onSubmit = async (e) => {
		e.preventDefault();
    setStatus("Enviando...");
    //const { name, email, subject, message } = e.target.elements;
    let details = {
      name: form.name,
      email: form.email,
      subject: form.subject,
      message: form.message,
    };
    let response = await fetch('/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(details)
    });
    setStatus("Enviar");
    let result = await response.json();

    if(!result.ok){
      setResponse(
        <ContactToast>
          <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-red-100 text-red-500 dark:bg-red-800 dark:text-red-200">
            <HiX className="h-5 w-5" />
          </div>
          <div className="ml-3 text-sm font-normal">
            {t("Error sending the Email")}
          </div>
        </ContactToast>
      );
      return
    }

    setResponse(
			<ContactToast>
				<div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200">
					<HiCheck className="h-5 w-5" />
				</div>
				<div className="ml-3 text-sm font-normal">
					{t("Email sent successfully")}
				</div>
			</ContactToast>
		);

    alert(result.status);
		clearForm();
	};

	const clearForm = () => {
		setForm({
			name: "",
			email: "",
			subject: "",
			message: "",
		});
	};

	return (
		form && (
			<div>
				{response}
				<form
					className="w-full text-gray-700 space-y-4 transition-all text-center"
					onSubmit={onSubmit}
				>
					<input
						required
						type="text"
						placeholder="Nombre"
						className="w-full px-5 py-2.5 border-gray-300 placeholder:text-gray-400 focus:ring-0 focus:border-gray-400 focus:shadow transition-shadow rounded-xl"
						value={form.name}
						onChange={(e) => setForm({ ...form, name: e.target.value })}
						style = {{fontSize: 'calc(10px + 0.8vmin)'}}
					/>
					<input
						required
						type="email"
						placeholder="Email"
						className="w-full px-5 py-2.5 border-gray-300 placeholder:text-gray-400 focus:ring-0 focus:border-gray-400 focus:shadow transition-shadow rounded-xl"
						value={form.email}
						onChange={(e) => setForm({ ...form, email: e.target.value })}
						style = {{fontSize: 'calc(10px + 0.8vmin)'}}
					/>
					<input
						required
						type="text"
						placeholder="Asunto"
						className="w-full px-5 py-2.5 border-gray-300 placeholder:text-gray-400 focus:ring-0 focus:border-gray-400 focus:shadow transition-shadow rounded-xl"
						value={form.subject}
						onChange={(e) => setForm({ ...form, subject: e.target.value })}
						style = {{fontSize: 'calc(10px + 0.8vmin)'}}
					/>
					<textarea
						required
						placeholder="Mensaje"
						rows={6}
						className="w-full px-5 py-2.5 border-gray-300 placeholder:text-gray-400 focus:ring-0 focus:border-gray-400 focus:shadow transition-shadow rounded-xl"
						value={form.message}
						onChange={(e) => setForm({ ...form, message: e.target.value })}
						style = {{fontSize: 'calc(10px + 0.8vmin)'}}
					/>
					<button
						type="submit"
						className="btn-submit"
					>
						Enviar
					</button>
				</form>
			</div>
		)
	);
}
