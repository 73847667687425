import '../App.css';
import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import imagepc from '../assets/pc-img.png';

function About() {
  return (
    <div className="App">
      <div className="body-container">
        <Box sx={{ flexGrow: 1}}>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 12, md: 12 }}>
            <Grid item xs={8}> 
              <h1 className="title">Somos</h1>
              <div style={{textJustify:'inter-word', textAlign:'justify'}}>
                <p>1NET nace en 2020 cuenta con más de 6 años de experiencia en el mercado de IT & Telecomunicaciones. 1NET está integrado por un equipo de Profesionales especializados en IP, MPLS, Seguridad, Wireless, Data Center y más.</p> <br/>
                <p><b>Visión:</b> ser reconocidos como líderes en la prestación de servicios de networking y seguridad en el mercado, brindando soluciones innovadoras y de alta calidad a nuestros clientes. </p> <br/>
                <p><b>Misión:</b> ofrecer soluciones integrales en networking y seguridad a empresas jóvenes, proporcionando un servicio personalizado y eficiente, y creando una relación de confianza y larga duración con nuestros clientes. Nuestro objetivo es mejorar la eficiencia y seguridad de las redes de nuestros clientes, permitiéndoles enfocarse en su negocio y alcanzar sus metas.</p>
              </div>
            </Grid>
            <Grid item xs={4}> 
              <div className="image-list-container" style={{marginTop: '4vw', marginBottom:'4vw'}}>
                <img src={imagepc} alt="pc" className="imagepc" style={{ width: '50vh', height: '50vh'}}/>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
}

export default About;
