import '../App.css';
import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate  } from 'react-router-dom';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import { login } from "../actions/auth";

const handleButtonClick = () => {
  window.location.href = '/blog'
}

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        Este campo es obligatorio
      </div>
    );
  }
};

const Login = (props) => {
  let navigate = useNavigate();

  const form = useRef();
  const checkBtn = useRef();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const { isLoggedIn } = useSelector(state => state.auth);
  const { message } = useSelector(state => state.message);

  const dispatch = useDispatch();

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      dispatch(login(username, password))
        .then(() => {
          navigate(`/blog/profile`);
          window.location.reload();
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  if (isLoggedIn) {
    return <Navigate to={`/blog/profile`} />;
  }

  return (
    <div className="App">
      <div className="auth">
        <h1 className="title">Inicia Sesión</h1>
        <div className="auth-form">

          <Form onSubmit={handleLogin} ref={form}>
            <div className="form-item">
              <label htmlFor="username">Nombre de usuario:</label>
              <Input
                type="text"
                className="form-item"
                name="username"
                placeholder="Ingrese nombre de usuario"
                value={username}
                onChange={onChangeUsername}
                validations={[required]}
              />
            </div>

            <div className="form-item">
              <label htmlFor="password">Contraseña:</label>
              <Input
                type="password"
                className="form-item"
                name="password"
                placeholder="Ingrese contraseña"
                value={password}
                onChange={onChangePassword}
                validations={[required]}
              />
            </div>

            <div className="form-item">
              <button className="btn-submit" disabled={loading} style={{width: '100%'}}>
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Ingresar</span>
              </button>
            </div>

            <div>
              <a href='/register' className='App-link'>¿No tienes cuenta? Regístrate</a>
            </div>

            {message && (
              <div className="form-item">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
            <CheckButton style={{ display: "none" }} ref={checkBtn} />
          </Form>
        </div>
        <button className="btn-outlined" onClick={handleButtonClick}>Volver al blog</button>
      </div>
    </div>
  );
};

export default Login;
