import '../App.css';
import '../App.css';
import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import logoA10 from '../assets/logos1net/a10networks_logo.png';
import logoCisco from '../assets/logos1net/cisco_logo.png';
import logoFortinet from '../assets/logos1net/fortinet_logo.png';

function StandardImageList() {
  return (
    <div className='image-list-container'>
    <ImageList cols={3}>
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src={item.img}
            style={{ width: '20vw', height: '20vw', marginRight: '2vw', marginLeft: '2vw' }}
            alt={item.title}
            loading="eager"
          />
        </ImageListItem>
      ))}
    </ImageList>
    </div>
  );
}

const itemData = [
  {
    img: logoCisco,
    title: 'Cisco',
  },
  {
    img: logoFortinet,
    title: 'Fortinet',
  },
  {
    img: logoA10,
    title: 'A10 Networks',
  },
];

function Partners() {
  return (
    <div className="App">
      <div className="body-container">
        <h1 className="title">Nuestros Partners</h1>
        <StandardImageList/>
      </div>
    </div>
  );
}

export default Partners;