import logo from '../assets/logo-1net-blanco-horizontal.svg';
import menuIcon from '../assets/menu-icon.svg'
import '../App.css'
import { useState } from 'react'

const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false)

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar)
  }

  function handleClickScroll(id) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  // const handleBlogClick = () => {
  //   window.location.href = '/blog'
  // }

  const handleLogoClick = () => {
    window.location.href = '/'
  }

  return (
    <div className='App'>
      <nav className="navbar">
        <div className="container">
          <div className="logo">
            <button onClick={handleLogoClick} style={{background: 'none', border: 'none', cursor: 'pointer'}}>
              <img src={logo} className="App-logo" alt="logo" />
            </button>
          </div>
          <div className="menu-icon" onClick={handleShowNavbar}>
            <img src={menuIcon} alt="menu" />
          </div>
          <div className={`nav-elements  ${showNavbar && 'active'}`}>
            <ul>
              <li>
                <button className="btn-scroll" onClick={() => handleClickScroll('home')}>Home</button>
              </li>
              <li>
                <button className="btn-scroll" onClick={() => handleClickScroll('clients')}>Clientes</button>
              </li>
              <li>
                <button className="btn-scroll" onClick={() => handleClickScroll('partners')}>Partners</button>
              </li>
              <li>
                <button className="btn-scroll" onClick={() => handleClickScroll('about')}>Nosotros</button>
              </li>
              <li>
                <button className="btn-scroll" onClick={() => handleClickScroll('services')}>Servicios</button>
              </li>
              <li>
                <button className="btn-scroll" onClick={() => handleClickScroll('contact')}>Contacto</button>
              </li>
              {/* <li>
                <button className="btn-scroll" onClick={handleBlogClick}>Blog</button>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Navbar
