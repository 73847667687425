import '../App.css';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import ServiceCard from '../components/ServiceCard';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center' ,
  margin: 'auto',
  height: '100%',
  color: '#453e3c',
  fontFamily: 'Poppins',
  fontSize: 'calc(10px + 0.8vmin)',
}));

const services = [
  {
    id: 1,
    title: 'Implementación y despliegue de proyectos para redes empresariales inalámbricas (wireless) y cableadas (wired)',
  },
  {
    id: 2,
    title: 'Creación y consultoría de RFPs según estudio de requerimientos',
  },
  {
    id: 3,
    title: 'Arquitectura y diseño de redes e infraestructura empresarial',
  },
  { 
    id: 4,
    title: 'Homologaciones y pruebas de equipamiento de red',
  },
  {
    id: 5,
    title: 'Recursos especializados de ingeniería (outsourcing)',
  },
  {
    id: 6,
    title: 'Servicios de PMO',
  },
  {
    id: 7,
    title: 'Asistencia y soporte remoto/presencial ante incidentes para redes empresariales inalámbricas (wireless) y cableadas (wired)',
  },
  {
    id: 8,
    title: 'Monitoreo de infraestructura (NOC)',
  },
  {
    id: 9,
    title: 'Entrenamiento profesional en diversas tecnologías',
  },
];

function Services() {
  return (
    <div className="App">
      <div className="body-container">
        <h1 className="title">Servicios</h1>
        <Box sx={{ flexGrow: 1}}>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 12, md: 12 }}>
            {services.map((service) => (
              <Grid item xs={4}>
                <Item><ServiceCard title={service.title} id={service.id} /></Item>
              </Grid>
            ))}
          </Grid>
        </Box>
      </div>
    </div>
  );
}

export default Services;