import './App.css';
import Home from './pages/Home'
// import Blog from './pages/Blog'
import Post from './pages/Post'
import Profile from './components/Profile'
import Login from './components/Login'
import Register from './components/Register'
import NavbarBlog from './components/NavbarBlog'
import Footer from './components/Footer'
import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
} from "react-router-dom";
import BoardUser from './components/BoardUser';
import BoardModerator from './components/BoardModerator';
import BoardAdmin from './components/BoardAdmin';
import { Provider } from "react-redux";
import store from "./store";

const Layout = () => {
  return (
    <div className="App">
      <div className="navbar">
        <NavbarBlog />
      </div>
      <Outlet />
      <div className="footer">
        <Footer />
      </div>
    </div>
  )
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>,
  },
  {
    path: "/blog",
    element: <Layout/>,
    children: [
      {
        path: "/blog/post/:id",
        element: <Post/>,
      },
      {
        path: "/blog/profile",
        element: <Profile/>,
      },
      {
        path: "/blog/user",
        element: <BoardUser/>,
      },
      {
        path: "/blog/mod",
        element: <BoardModerator/>,
      },
      {
        path: "/blog/admin",
        element: <BoardAdmin/>,
      },
    ]
  },
  {
    path: "/login",
    element: <Login/>,
  },
  {
    path: "/register",
    element: <Register/>,
  },
]);

const App = () => {
  return (
    <Provider store={store}>
      <div className="App">
        <RouterProvider router={router}/>
      </div>
    </Provider>
  );
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <App/>
);

export default App;

