import '../App.css';
//import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import About from '../pages/About';
import Clients from '../pages/Clients';
import Partners from '../pages/Partners';
import Services from '../pages/Services';
import Contact from '../pages/Contact';
import Blog from '../pages/Blog';
import DemoCarousel from '../components/Carousel';
import Fab from '@mui/material/Fab';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import React, { useState, useEffect } from "react";
import UserService from "../services/user.service";

function handleClickScroll(id) {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

function Home() {
  const [content, setContent] = useState("");

  useEffect(() => {
    UserService.getPublicContent().then(
      (response) => {
        setContent(response.data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        setContent(_content);
      }
    );
  }, []);

  return (
    <div className="App">
      <div className="navbar" id="top">
        <Navbar />
      </div>
      <div id="home">
        <DemoCarousel/>
      </div>
      <div className="body-container">
        <div id="about" className="container2">
          <About />
        </div>
        <hr/>
        <div id="services" className="container2">
          <Services />
        </div>
        <hr/>
        <div id="clients" className="container2">
          <Clients />
        </div>
        <hr/>
        <div id="partners" className="container2">
          <Partners />
        </div>
        <hr/>
        <div id="contact" className="container2">
          <Contact />
        </div>
        {/* <hr/>
        <div id="blog" className="container2">
          <Blog />
        </div> */}
      </div>
      <div className="up-btn">
        <Fab color="primary" aria-label="up" onClick={() => handleClickScroll('top')}>
          <ArrowUpwardIcon />
        </Fab>
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
}

export default Home;
