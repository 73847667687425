import '../App.css';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

function Blog() {
  return (
    <div className="App">
      <div className="container2">
        <div className="blog-header">
          <h1 className="title" style={{fontSize: 'calc(10px + 4vmin)', fontWeight: 600}}>Blog</h1>
          <div style={{display: 'none'}}>
            <IconButton aria-label="add-post" color="primary"><AddCircleOutlineIcon/></IconButton>
          </div>
        </div>
        <div className="blog-wrapper">
          <iframe
          className="iframe-responsive"
          src="https://1net-alberigroup.blogspot.com"
          title="1net-blog"
          frameborder="0"
          sandbox=""
          />
        </div>
        <div className="button-container">
          <button className="btn-outlined">
            <a href="https://1net-alberigroup.blogspot.com/" style={{fontSize: 'calc(10px + 1vmin)'}} target="_blank">Ir al Blog</a>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Blog;