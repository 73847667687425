import React, { Component } from 'react';
//import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import '../App.css'
import { Carousel } from 'react-responsive-carousel';
import pic1 from '../assets/1.jpg';
import pic2 from '../assets/2.jpg';
import pic3 from '../assets/3.jpg';

const legendStyle = {
  fontSize: 'calc(10px + 2vw)',
  opacity: '1',
  fontWeight: '400',
  background: 'none',
  top: '5vw',
  padding: '0 10%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

class DemoCarousel extends Component {
  render() {
    return (
      <Carousel 
      showStatus={false} 
      infiniteLoop={true} 
      showThumbs={false} 
      autoPlay={true} 
      interval={5000} 
      transitionTime={1000}>
        <div>
          <img src={pic1} alt="" />
          <p className="legend" style={legendStyle}>Bienvenid@s a 1Net</p>
        </div>
        <div>
          <img src={pic2} alt="" />
          <p className="legend" style={legendStyle}>Amplia experiencia en el mercado de IT y telecomunicaciones</p>
        </div>
        <div>
          <img src={pic3} alt="" />
          <p className="legend" style={legendStyle}>Profesionales especializados en IP, MPLS, seguridad, wireless, data center y más</p>
        </div>
      </Carousel>
    );
  }
};

//.render(<DemoCarousel />, document.querySelector('.demo-carousel'));
export default DemoCarousel;

// Don't forget to include the css in your page

// Using webpack or parcel with a style loader
// import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';

// Using html tag:
// <link rel="stylesheet" href="<NODE_MODULES_FOLDER>/react-responsive-carousel/lib/styles/carousel.min.css"/>