import '../App.css';
import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import logoLLA from '../assets/logos1net/lla_logo.png';
import logoVTR from '../assets/logos1net/vtr_logo.png';
import logoWom from '../assets/logos1net/wom_logo.png';
import logoNtt from '../assets/logos1net/ntt_logo.png';
import logoNSP from '../assets/logos1net/nsp_logo.png';
import logoGtd from '../assets/logos1net/gtd_logo.png';

function hideAll(){
  for (let i = 0; i < itemData.length; i++) {
    const info = document.getElementById(`info-${itemData[i].title}`);
    info.style.display = 'none';
  } 
}

function onClickButton (item) {
  const info = document.getElementById(`info-${item.title}`);
  if (info.style.display === 'block') {
    hideAll();
  } else {
    hideAll();
    info.style.display = 'block';
  }
}

function parseInfo (info) {
  return info.split(';').map((item) => {
    return (
      <li>- {item}</li>
    )
  })
}

function StandardImageList() {
  return (
    <div className='image-list-container'>
    <ImageList cols={3}>
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <button onClick={() => onClickButton(item)}>
            <img
              src={item.img}
              style={{ width: '20vw', height: '20vw', marginRight: '2vw', marginLeft: '2vw' }}
              alt={item.title}
              loading="eager"
            />
          </button>
          <div id={ `info-${item.title}` } style={{display: 'none'}} className="info-container">
            <p className="info-text">
              Nuestros proyectos con {item.title}: {parseInfo(item.info)}
            </p>
          </div>
        </ImageListItem>
      ))}
    </ImageList>
    </div>
  );
}

const itemData = [
  {
    img: logoLLA,
    title: 'LLA',
    info: 'Migración de servicios BackHaul',
  },
  {
    img: logoVTR,
    title: 'VTR',
    info: 'Despliegue IPv6 para servicios de Internet en ISP; Migraciones de PE Cisco 7600 hacia Cisco ASR 9010',
  },
  {
    img: logoNtt,
    title: 'NTT',
    info: 'Soporte de en despliegue Cisco SDWAN',
  },
  {
    img: logoWom,
    title: 'WOM',
    info: 'Consultoria en redes CDN',
  },
  {
    img: logoGtd,
    title: 'GTD',
    info: 'Servicios ONSITE para proyectos SDWAN (Huawei, Meraki, Fortinet); Consultoría en redes CDN',
  },
  {
    img: logoNSP,
    title: 'NSP',
    info: 'Soporte para equipos de DataCenter; Migración de equipo Firewall Fortinet',
  }
];

function Clients() {
  return (
    <div className="App">
      <div className="container2">
        <h1 className="title" style={{marginTop: '3vh'}}>Nuestros Clientes</h1>
        <StandardImageList/>
      </div>
    </div>
  );
}

export default Clients;