import '../App.css'

const Footer = () => {
  return (
    <div className='App'>
      <div className="footer">
        <p>© 2022 1Net. Puerta del sol #80, Las Condes. Santiago. RM. Chile </p>
      </div>
    </div>
  )
}

export default Footer