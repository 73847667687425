import '../App.css';
import { CiMail } from "react-icons/ci";
import { FaLinkedinIn, FaWhatsapp } from "react-icons/fa";
import ContactForm from '../components/ContactForm';

export default function Contact() {
	return (
    <div className="body-container" style={{marginTop: 0}}>
      <div id="contact" className="flex flex-col items-center gap-5 my-12 py-2">
        {/* Title */}
        <h1 className="title">Contáctanos</h1>
        {/* Horizontal line */}
        <div className="border-b-2 border-black w-20" />
        {/* Socials */}
        <div className="flex w-full justify-center gap-5 items-center text-3xl">
          {/* Whatsapp */}
          <a
            href={"https://api.whatsapp.com/send?phone=56969166154"}
            target="_blank"
            className="border border-green-400 text-green-400 p-3 rounded-full hover:text-white hover:bg-green-400 hover:drop-shadow transition-all"
          >
            <FaWhatsapp />
          </a>
          {/* Linkedin */}
          <a
            href={"https://www.linkedin.com/company/1net-spa/"}
            target="_blank"
            className="border border-blue-500 text-blue-500 p-3 rounded-full hover:text-white hover:bg-blue-500 hover:drop-shadow transition-all"
          >
            <FaLinkedinIn />
          </a>
          {/* Email */}
          <a 
            href={"mailto:contacto@1net.cl"}
            target="_blank"
            className="font-light text-5xl flex items-center gap-4 text-gray-800">
            <div className="border border-gray-800 text-3xl p-3 rounded-full hover:text-white hover:bg-gray-800 hover:drop-shadow transition-all">
              <CiMail />
            </div>
            <p className="soft-body" style={{fontSize: 'calc(10px + 1vmin)'}}>contacto@1net.cl</p>
          </a>
        </div>
        {/* Content */}
        <div className="flex flex-col md:flex-row justify-around gap-5 w-full text-gray-700 font-light items-start">
          {/* Left */}
          <div className="flex flex-col gap-5 items-center justify-center w-full">
            <iframe
              className="rounded-xl"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6660.779864754015!2d-70.578507!3d-33.413077!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662cf1f639400a9%3A0xfa7115b4716ec2dc!2sPuerta%20del%20Sol%2080%2C%20oficina%20216%2C%20Las%20Condes%2C%20Regi%C3%B3n%20Metropolitana!5e0!3m2!1sen!2scl!4v1676396623850!5m2!1sen!2scl"
              width="100%"
              height="400"
              style={{ border: 0 }}
              allowFullScreen={true}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
          {/* Right */}
          <div>
            <h1 className="title" style={{textAlign: 'center'}}>Formulario de Contacto</h1>
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
	);
}
