import logo from '../assets/logo-1net-blanco-horizontal.svg';
import menuIcon from '../assets/menu-icon.svg';
import '../App.css'
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { logout } from "../actions/auth";
import { clearMessage } from "../actions/message";

const NavbarBlog = () => {
  const [showNavbar, setShowNavbar] = useState(false)
  const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);

  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  let location = useLocation();

  useEffect(() => {
    if (["/login", "/register"].includes(location.pathname)) {
      dispatch(clearMessage()); // clear message when changing location
    }
  }, [dispatch, location]);

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      setShowModeratorBoard(currentUser.roles.includes("ROLE_MODERATOR"));
      setShowAdminBoard(currentUser.roles.includes("ROLE_ADMIN"));
    } else {
      setShowModeratorBoard(false);
      setShowAdminBoard(false);
    }
  }, [currentUser]);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar)
  }

  const handleBlogClick = () => {
    window.location.href = '/blog'
  }

  const handleLogoClick = () => {
    window.location.href = '/'
  }

  const handleLoginClick = () => {
    window.location.href = '/login'
  }

  const handleModeratorClick = () => {
    window.location.href = '/blog/mod'
  }

  const handleAdminClick = () => {
    window.location.href = '/blog/admin'
  }

  const handleUserClick = () => {
    window.location.href = '/blog/user'
  }

  function handleProfileClick(username) {
    window.location.href = `/blog/profile`
  }

  return (
    <div className='App'>
      <nav className="navbar">
        <div className="container">
          <div className="logo">
            <button onClick={handleLogoClick} style={{background: 'none', border: 'none', cursor: 'pointer'}}>
              <img src={logo} className="App-logo" alt="logo" />
            </button>
          </div>
          <div className="menu-icon" onClick={handleShowNavbar}>
            <img src={menuIcon} alt="menu" />
          </div>
          <div className={`nav-elements  ${showNavbar && 'active'}`}>
            <ul>
              <li>
                <button className="btn-scroll" onClick={handleLogoClick}>Volver a Home</button>
              </li>

              {showModeratorBoard && (
                <li style={{display: 'none'}}>
                  <button className="btn-scroll" onClick={handleModeratorClick}>Moderador</button>
                </li>
              )}
              
              {showAdminBoard && (
                <li style={{display: 'none'}}>
                  <button className="btn-scroll" onClick={handleAdminClick}>Admin</button>
                </li>
              )}

              {currentUser && (
                <li style={{display: 'none'}}>
                  <button className="btn-scroll" onClick={handleUserClick}>Usuario</button>
                </li>
              )}

              <li>
                <button className="btn-scroll" onClick={handleBlogClick}>Blog</button>
              </li>

              {currentUser ? (
                <>
                  <li className="nav-item" style={{display: 'none'}}>
                    <button className="btn-scroll" onClick={handleProfileClick}>{currentUser.username}</button>
                  </li>
                  <li className="nav-item" style={{display: 'none'}}>
                    <a href="/login" className="nav-link" onClick={logOut}>
                      Cerrar sesión
                    </a>
                  </li>
                </>
              ) : (
                <li className="nav-item" style={{display: 'none'}}>
                  <button className="btn-scroll" onClick={handleLoginClick}>Iniciar Sesión</button>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default NavbarBlog