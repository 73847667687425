import '../App.css';
import * as React from 'react';
import ReactCardFlip from 'react-card-flip';

import service1Logo from '../assets/services/service1.png';
import service2Logo from '../assets/services/service2.png';
import service3Logo from '../assets/services/service3.png';
import service4Logo from '../assets/services/service4.png';
import service5Logo from '../assets/services/service5.png';
import service6Logo from '../assets/services/service6.png';
import service7Logo from '../assets/services/service7.png';
import service8Logo from '../assets/services/service8.png';
import service9Logo from '../assets/services/service9.png';

class ServiceCard extends React.Component {
  constructor({title, id}) {
    super();
      this.state = {
      isFlipped: false,
      title: title,
      id: id,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));
  }

  getImg() {
    switch (this.state.id) {
      case 1:
        return service1Logo;
      case 2:
        return service2Logo;
      case 3:
        return service3Logo;
      case 4:
        return service4Logo;
      case 5:
        return service5Logo;
      case 6:
        return service6Logo;
      case 7:
        return service7Logo;
      case 8:
        return service8Logo;
      case 9:
        return service9Logo;
      default:
    }
  }

  render() {
    return (
      <ReactCardFlip isFlipped={this.state.isFlipped} flipDirection="horizontal">
        <div className="service-card">
          <button onClick={this.handleClick}><img src={this.getImg()} alt={this.state.title} style={{width:'20vh', height:'20vh'}}/></button>
        </div>

        <div className="service-card">
          <button onClick={this.handleClick}>{this.state.title}</button>
        </div>
      </ReactCardFlip>
    )
  }
}

export default ServiceCard;