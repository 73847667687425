import '../App.css';

function Post() {
  return (
    <div className="App">
      <div className="blog-container">
        <div className="blog-header">
          <h1 className="title">Blog post</h1>
        </div>
      </div>
    </div>
  );
}

export default Post;